<template lang="pug">
  b-row.mb-5
    b-col
      router-view
</template>

<script>
export default {
  name: 'manager-accounts-organizations',
}
</script>
